export const useSrBrandingStore = defineStore('srBranding', {
  state: (): { url: string } => ({
    url: '',
  }),
  actions: {
    setUrl(url: string) {
      this.url = url
    },
  },
})
